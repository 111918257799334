@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
     /* background: #fff url('./ajax-loader.gif') center center no-repeat;  */
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    /* src: url('./fonts/slick.eot'); */
    /* src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg'); */
}
/* Arrows */
.slick-prev, .slick-next {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 65px;
    height: 65px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: 1px solid #c0c2d4;
    outline: none;
    background: transparent;
    border-radius: 50%;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    color: #bec1d3;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: top;
    line-height: 65px;
    width: 100%;
    height: 100%;
}
.slick-prev {
    left: -65px;
}
[dir='rtl'] .slick-prev {
    right: -65px;
    left: auto;
}
.slick-prev:before
{
    content: '\e908';
    font-family: 'icomoon';
}
[dir='rtl'] .slick-prev:before
{
    content: '\e908';
    font-family: 'icomoon';
}
.slick-next {
    right: -65px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '\e908';
    font-family: 'icomoon';
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
[dir='rtl'] .slick-next:before
{
    content: '\e908';
    font-family: 'icomoon';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}
